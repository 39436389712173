<template>
  <!--pages/index.vue-->
  <div class="container-fluid ps-0 pe-0">
    <div class="row ms-0 me-0">
        <div class="col-2 class_left_col">
          <LazyLogoList v-if="((enforce_login == false) || (isAuthenticated() && (! is_trial_org)))" :key="'logo_list_' + rerender_logo_list_ref.valueOf()"/>
        </div>
        <div class="col col-10 top-runbook-container">
            <div class="d-flex border justify-content-between filter-area flexwrap">
                <div style="max-width: 45vw">
                  <h1 class="line-up-with-logo page_header fs-3" v-if="searchKey.length > 0">Search result for '{{ searchKey }}'</h1>
                  <h1 class="line-up-with-logo page_header fs-3" v-else-if="linkto_task_title != ''">Tasks that reuse '{{ linkto_task_title }}'</h1>
                  <h1 class="line-up-with-logo page_header fs-3" v-else>Top runbooks</h1>
                </div>

                <div class="filter-btn-area mt-3">

                  <Filters 
                  :key="selectedFilter"
                  :items="filterButtons"
                  @get_filtered_data="getfilteredData" />
                </div>

                <div class="select_all_task_container" v-if="isAdmin()">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input type="checkbox" id="select_all_task" @change="toggle_tasks"/>
                        </td>
                        <td>
                          <button class="btn btn-sm btn-outline-primary" @click.stop.prevent="delete_tasks">Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div v-if="searchStore.getSearchResult.length > 0" :key="'search_result_container_' + search_criteria_change_counter.valueOf()">
                <TaskList :list="searchStore.getSearchResult" :key="searchStore.getSearchResult[0].id"/>
              </div>
              <div id="near_bottom_detection">&nbsp;</div>
        </div>
        <!-- <div class="col-2 class_right_col"> </div> -->
    </div>
  </div>
</template>

<script setup>
let enforce_login = await getEnforceLogin();

const settingStore = useSettingsStore();
let settings = settingStore.getSetting;
if ((settings == null) || (Object.keys(settings).length == 0)) {
  await settingStore.setSetting(null);
}

const searchStore = useSearchStore();
const loading = useLoadingState();
import { isEnabledShowSearchResultFromCommunity } from '~/services/settingService'

let space_dropdown_state = useSpaceDropdownState();

searchStore.setSearchType("search");
/*
We are trying to cut down the number of API calls from the frontend.  If the backend is already doing the search, do not clear out the search result.
Clear the search result where appropriate, but not here.  When we reload the home page, we should not see a search request from Chrome Dev Console.
If we reset next_page_key and has_more_results here, it has the same affect as clearing the search result (not technically the same), but it breaks
infinite scrolling, because if we scrolldown, we will notice that some runbooks are not displayed.

Putting back the next_page_key, has_more_results, as well as clearing the search result as they were previously because 
trying to optimize this other weird issues:
1.  After creating a task, clicking on the home icon does not display the task that was just created.
2.  Sometimes, click on the home icon display an empty blank screen (this is weird, not explanable)
*/
searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true })
searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
searchStore.setSearchData([]);

const route = useRoute();        
const searchKey = ref('');

const query = computed(() => {
    let  query = ''
    if (route.query) {
        query = route.query
    }
    return query
})

const env = await getEnv();
let community_url = getCommunityURL();
const runtimeConfig = useRuntimeConfig();
//console.log("CONFIG:", runtimeConfig);
//console.log("ENVs:", env)
const authState = useAuthState();
const search_criteria_change_counter = ref(0);  // This is used to re-render the search result list when search criteria changed.

useSeoMeta({
  title: 'Dagknows',
  ogTitle: 'Dagknows',
  description: 'GenAI created and expert curated automation',
  ogDescription: 'GenAI created and expert curated automation',
  ogImage: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
  twitterCard: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
});

const userInfoStore = useUserInfoStore();
let user_info = userInfoStore.getUserInfo
let org = user_info.org || ''
let is_trial_org = false;
if (org?.toLowerCase().startsWith('trial')) {
  is_trial_org = true;
}

onMounted(async () => { 
  remove_leftover_tooltip();
  // See DD-1193 (after searching and hit the enter key, clicking on a task display some other task)
  if (query.value.hasOwnProperty('autocomplete')) {
    await handleSearchOrTag();
  }

  if (isAuthenticated()) {
    if ((typeof(window) != 'undefined') && (typeof ($) == 'undefined')) {
      // We are running in the browser, and the lightweight zepto library is not yet loaded, then load it.
      // We are depend on this library in quite a few places.
      await loadScript('/vstatic/zepto-1.2.0/zepto.min.js');      
    }
  }

  pageMountedCode();
});

onBeforeMount(async () => {
  await showWaitPage();
})

onBeforeUnmount(async () => {
  pageUnmoutedCode();
});

let initial_page_load = true;

let show_all_tasks_state = useShowAllTasksState();

let isEnabledShowCommunityResultFromDB = false;
isEnabledShowCommunityResultFromDB = await isEnabledShowSearchResultFromCommunity(settingStore);

let userDKRoleStore = useUserDKRolesStore();
let dkroles = userDKRoleStore.getUserDKRoles;

let getSearchResult = async (query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, include_community, favorited, pending) => {
  if (! initial_page_load) {
    loading.value = true;
  }
  let show_all_tasks = false;
  if (typeof(window) != 'undefined') {
    let elem = document.querySelector('.filter_show_all');
    if (elem != null) {
      show_all_tasks = elem.checked ? true : false
    }
  }

  let wsid = space_dropdown_state.value;
  if (wsid != '') {
    // If a techrep user was not given access to any space, the user was able to see all the tasks 
    // when the user switch the space.  To prevent this, we set wsid to empty.  However, we do not 
    // want to set wsid to empty for supremo and admin
    if (! dkroles.hasOwnProperty(wsid)) {
      wsid = '';
    }
    if (['supremo','admin'].includes(user_info.role?.toLowerCase())) {
      wsid = space_dropdown_state.value;
    }
  }

  await searchStore.doSearch(query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, include_community, favorited, show_all_tasks, wsid, pending);
  if (! initial_page_load) {
    loading.value = false;
  }
}

let linkto_task_title = '';
let parent_task_ids = [];
/*
For some reason, inside the handleSearchOrTag function, we can only have one async / await function.
If we have multiple of those, we will face the error "A composable that requires access to the Nuxt 
instance was called outside of a plugin, Nuxt hook, Nuxt middleware, or Vue setup function".  This 
error only happens on the server side SSR leg.  This happens when we click on the URL bar and hit 
the enter key or we manually reload the page.  This does not happen on the client side.  Because of 
this, one strategy to work around this issue is to put whatever we can directly in this script setup 
block, and keep minimal amount of code inside the handleSearchOrTag function.  Notice that we are 
calling the handleSearchOrTag function inside this script setup block, and we are calling it with 
the await keyword.  This error happens because our getUrl composable needs access to runtime config.
Again, this only happens on the server side SSR leg, which is weird.  Apart from putting code directly 
inside the script setup block, and not inside the handleSearchOrTag function, we should make sure 
that we use the await keyword for each async function.
*/

const handleLinkTo = async() => {
  loading.value = true;
  let linkto_task_id = query.value.linkto;
  let task = await readTask(linkto_task_id, {});
  linkto_task_title = task.title;
  parent_task_ids = task['parent_tasks'];
}

if ((typeof(window) == 'undefined')  && (query.value.hasOwnProperty('linkto'))) {
  // Populate the parent_task_ids array
  handleLinkTo();
}

const handleSearchOrTag = async () => {
  if ((enforce_login) && (! isAuthenticated())) {
    return;
  }
  
  linkto_task_title = '';
  searchKey.value = '';

  let query_string = '';
  let tag = '';
  let userid = '';
  let collaborator = '';
  let url_prefix = '';
  let order_by = '';
  let with_pending_perms = false;
  let favorited = '';
  let pending = '';
  
  if (query.value.hasOwnProperty('tag')) {
    //search for tag
    searchKey.value = query.value.tag;
    tag = query.value.tag;
  }

  if (query.value.hasOwnProperty('q')) {
    //search for regular query
    searchKey.value = query.value.q;
    query_string = query.value.q;
  }

  let include_community = false;
  if (query.value.hasOwnProperty('autocomplete')) {
    // If the search is coming from the autocomplete searchbox, then include result from community, 
    // if the flag from the database permits.
    include_community = isEnabledShowCommunityResultFromDB;
  }

  if (query.value.hasOwnProperty('order_by')) {
    // If we have the order_by parameter, use it
    order_by = query.value.order_by;
  } else if (include_community) {
    // If we set order_by to an empty string, it will be default to 'elastic' so we will use ranking provided by elasticsearch
    order_by = '';
  } else if (! query.value.hasOwnProperty('q')) {
    // Home page default listing is by most_recent
    order_by = 'most_recent';
  } else {
    order_by = '';
  }

  if (query.value.hasOwnProperty('favorited')) {
    favorited = query.value.favorited;
    if (favorited == 'true') {
      order_by = '';
    }
  }

  if (query.value.hasOwnProperty('pending')) {
    pending = query.value.pending;
    if (pending == 'true') {
      order_by = '';
    }
  }

  if (include_community) {
    url_prefix = community_url;
  }

  if (query.value.hasOwnProperty('linkto')) {

    if (typeof(window) != 'undefined') {
      // Populate the parent_task_ids array when coming from a different pages from the browser
      await handleLinkTo();
    }

    let parent_tasks = await getTasks(parent_task_ids);
    searchStore.setSearchData([...parent_tasks])
    loading.value = false;

    /*
    In the above code for the "linkto" functionality, we first calls getTasks to fetch all the parent and 
    grand parent tasks, and then we call the setSearchData function.  I assume that we will then display 
    all the parent tasks.  Here we do not call the getSearchResult defined inside this file, which then 
    call the doSearch function defined in searchStore.  In other words, here, we do not do search.  
    My question here is: What would we do if we ever need to implement pagination for this 'linkto' 
    functionality?  Perhaps, we need to add this 'linkto' functionality to the search API?  That seems to 
    be an expensive option though.  Perhaps we do not ever need to implement pagination for the linkto 
    functionality.
    */
  } else {
    await getSearchResult(query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, include_community, favorited, pending);
  }
  if (typeof(window) != 'undefined') {
    setPageMountedState(true);
  }
}

//filter buttons
const filterButtons = ref(['Most recent', 'Most liked', 'Most linked', 'Most download'])
const selectedFilter = ref(filterButtons.value[0])

const getfilteredData = async (filter) => {
  selectedFilter.value = filter
  let filters = filter.replace(/ /g, "_").toLowerCase();
  let url = '';
  if (getValue(query.value,'q')) {
    url = 'q=' + getValue(query.value,'q');
  } else if (getValue(query.value,'tag')) {
    url = 'tag=' + getValue(query.value,'tag');
  }

  url = '?' + url + '&order_by=' + filters;

  if (filters.toLowerCase() == 'most_favorited') {
    url += '&favorited=true'
  } else if (filters == 'pending_approval') {
    url += '&pending=true';
  }

  await navigateToWrapper(url);
}

const { fetchMore } = useInfiniteScroll(loading, searchStore);

watch(fetchMore, async (newVal, oldVal) => {
  if(newVal === true){
    await handleSearchOrTag();
  }
})

let rerender_logo_list_ref = ref(0);
watch(space_dropdown_state, async (newVal, oldVal) => {
  searchStore.setSearchType("search");
  searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true })
  searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
  searchStore.setSearchData([]);
  rerender_logo_list_ref.value = rerender_logo_list_ref.value + 1;

  await handleSearchOrTag();
})

watch(show_all_tasks_state, async (newVal, oldVal) => {
  if(newVal !== oldVal){
    searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true });
    searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
    searchStore.setSearchData([]);
    search_criteria_change_counter.value++;
    window.scrollTo(0,0);
    await handleSearchOrTag();
  }
})

watch(query, async (newVal, oldVal) => {

  const keys = ['q', 'tag', 'order_by', 'linkto', 'autocomplete', 'favorited'];
  const change_detected = keys.some(key => getValue(newVal, key) !== getValue(oldVal, key));

  if (change_detected) {
    searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true });
    searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
    searchStore.setSearchData([]);
    search_criteria_change_counter.value++;
    window.scrollTo(0,0);
  }

  await handleSearchOrTag();
});

// See DD-1193 (after searching and hit the enter key, clicking on a task display some other task)
if (query.value.hasOwnProperty('autocomplete')) {
  searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true });
  searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
  searchStore.setSearchData([]);
} else {
  await handleSearchOrTag();
}

initial_page_load = false;

const toggle_tasks = (event) => {
  let state = event.target.checked;
  document.querySelectorAll('.task_checkboxes').forEach((elem) => {
    elem.checked = state;
  });
}

const delete_tasks = (event) => {

  let tasks = [];
  document.querySelectorAll('.task_checkboxes').forEach((elem) => {
    if (elem.checked) {
      tasks.push(elem.value)
    }
  })

  if (tasks.length == 0) {
    dkAlert("You did not select any task to delete!")
  } else {
    let selected_tasks = tasks.join(',')
    dkConfirm("Are you sure that we want permanently delete the selected tasks and their sub-tasks?", async function(){
      await showWaitPage();
      let result = await deleteTasks(selected_tasks);
      let waitTime = tasks.length * 200;
      if (waitTime > 2000) {
        waitTime = 2000;
      }
      if (waitTime < 1000) {
        waitTime = 1000; // Enforce a minimum wait time
      }
      await dummyWait(waitTime); // Wait for the backend to delete the selected tasks
      document.getElementById('select_all_task').checked = false;
      searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true })
      searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
      searchStore.setSearchData([]);
      await handleSearchOrTag();
    }, function(){});
  }
}
await generateCSRFToken();
</script>

<style scoped>
    .top-runbook-container {
        min-height: calc(100vh - 60px);
        padding-top: 10px;
        padding-right: 0px;
        padding-left: 0px;
        border-left: 1px solid #ccc;
        /* border-right: 1px solid #ccc; */
    }
    .top-runbook-container .tag {
        color: blue !important;
    }

    .filter-area{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 60px;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
    }

    .filter-btn-area{
      position: relative;

    }
</style>
